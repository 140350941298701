import React from 'react'
import { Component } from 'reflux'
import { toast } from 'react-toastify'
import { CarrierActions, CarrierStore, STATUS } from 'Stores/carrierStore'
import CarrierForm from 'Modules/carrier/carrierForm'

export default class CarrierCreate extends Component {
  constructor(props) {
    super(props)
    this.stores = [CarrierStore]
    this.storeKeys = ['status']
  }
  componentDidMount() {
    CarrierActions.createEmptyCarrier()
  }
  componentDidUpdate() {
    if (this.state.status === STATUS.UPDATE_SUCCESS) {
      toast.success('Carrier Created', {
        autoClose: 3000,
        onClose: () => {
          CarrierActions.clearFormToast()
        }
      })
      CarrierActions.clearFormToast(STATUS.REDIRECT)
    }
    if (this.state.status === STATUS.UPDATE_ERROR) {
      toast.error('Carrier Create Failed', {
        autoClose: 3000,
        onClose: () => {
          CarrierActions.clearFormToast()
        }
      })
      CarrierActions.clearFormToast(STATUS.REDIRECT)
    }
  }

  render() {
    return (
      <React.Fragment>
        <CarrierForm />
      </React.Fragment>
    )
  }
}
