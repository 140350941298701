import Proxies from 'Components/proxies'
import {
  fetchSuppliersRequest,
  fetchSuppliersFailed,
  fetchSuppliersSuccess,
  fetchSupplierDetailsRequest,
  fetchSupplierDetailsFailed,
  fetchSupplierDetailsSuccess,
  supplierCreationSuccess,
  supplierCreationFailure,
  isSavingSupplier,
  removeSupplierFailure,
  removeSupplierSuccess,
  fetchCommentsSuccess,
  removeCommentSuccess,
  createSupplierComment,
  fetchCommentsError,
  createCommentError,
  removeCommentError,
  createSupplierAccess,
  updateSupplierAccess,
  removeSupplierAccess,
  fetchCarriersSuccess
} from 'Reducers/supplierReducer'
import keyMirror from 'keymirror'

export const STATUS = keyMirror({
  FETCHING_SUPPLIERS: null,
  FETCHING_SUPPLIERS_SUCCESS: null,
  FETCHING_SUPPLIERS_FAILED: null,
  FETCHING_SUPPLIER_DETAILS: null,
  FETCHING_SUPPLIER_DETAILS_FAILED: null,
  FETCHING_SUPPLIER_DETAILS_SUCCESS: null,
  SUPPLIER_SUCCESS: null,
  SUPPLIER_FAILED: null,
  IS_SAVING: null,
  IS_REDIRECT: null,
  DELETE_SUPPLIER_FAILED: null,
  DELETE_SUPPLIER_SUCCESS: null,
  FETCHING_COMMENTS_ERROR: null,
  CREATE_COMMENT_ERROR: null,
  REMOVE_COMMENT_ERROR: null
})

export const getSuppliers = data => dispatch => {
  dispatch(fetchSuppliersRequest())
  Proxies.GET('suppliers/pool', data).then(responseJSON => {
    if (responseJSON && responseJSON.error) {
      dispatch(fetchSuppliersFailed())
    } else {
      dispatch(fetchSuppliersSuccess(responseJSON))
    }
  })
}

export const getSupplierDetails = SupplierID => dispatch => {
  dispatch(fetchSupplierDetailsRequest())
  Proxies.GET('suppliers/fetch', { SupplierID }).then(responseJSON => {
    if (responseJSON && responseJSON.error) {
      dispatch(fetchSupplierDetailsFailed())
    } else {
      dispatch(fetchSupplierDetailsSuccess(responseJSON))
    }
  })
}

export const saveSupplier = data => dispatch => {
  dispatch(isSavingSupplier())
  Proxies.POST('suppliers/replace', { ...data }).then(responseJSON => {
    if (responseJSON && responseJSON.error) {
      dispatch(supplierCreationFailure())
    } else {
      dispatch(supplierCreationSuccess(responseJSON))
    }
  })
}

export const deleteSupplier = data => dispatch => {
  dispatch(isSavingSupplier())
  Proxies.POST('suppliers/removeSupplier', data).then(responseJSON => {
    if (responseJSON && responseJSON.error) {
      dispatch(removeSupplierFailure())
    } else {
      dispatch(removeSupplierSuccess())
    }
  })
}

export const fetchComments = SupplierID => dispatch => {
  Proxies.GET('suppliercomments/fetch', { SupplierID }).then(responseJSON => {
    if (responseJSON && responseJSON.error) {
      dispatch(fetchCommentsError())
    } else {
      dispatch(fetchCommentsSuccess(responseJSON))
    }
  })
}

export const createComment = data => dispatch => {
  Proxies.POST('suppliercomments/createSupplierComment', data).then(responseJSON => {
    if (responseJSON && responseJSON.error) {
      dispatch(createCommentError())
    } else {
      dispatch(createSupplierComment(responseJSON))
    }
  })
}

export const removeComment = comment => dispatch => {
  Proxies.POST('suppliercomments/removeSupplierComment', { SupplierCommentID: comment.SupplierCommentID }).then(responseJSON => {
    if (responseJSON && responseJSON.error) {
      dispatch(removeCommentError())
    } else {
      dispatch(removeCommentSuccess(comment.SupplierCommentID))
    }
  })
}

export const updateAccess = data => dispatch => {
  if (data.SupplierAccessID <= 0) {
    dispatch(createSupplierAccess(data))
  } else {
    dispatch(updateSupplierAccess(data))
  }
}

export const deleteSupplierAccess = data => dispatch => {
  dispatch(removeSupplierAccess(data))
}

export const getCarriers = data => dispatch => {
  dispatch(fetchSuppliersRequest())
  Proxies.GET('carriers/pool', data).then(responseJSON => {
    if (responseJSON && !responseJSON.error) {
      dispatch(fetchCarriersSuccess(responseJSON))
    }
  })
}
