import Reflux from 'reflux'
import Proxies from 'Components/proxies'
const { GET, POST } = Proxies
import keyMirror from 'keymirror'
import 'moment/locale/da'

export const STATUS = keyMirror({
  IS_FETCHING: null,
  FETCHING_ERROR: null,
  UPDATE_SUCCESS: null,
  UPDATE_ERROR: null,
  DELETE_ERROR: null,
  DELETE_SUCCESS: null,
  TAKING_INPUT: null,
  IS_SAVING: null,
  REDIRECT: null
})

export const CarrierActions = Reflux.createActions([
  'getCarriers',
  'deleteCarrier',
  'clearToast',
  'createEmptyCarrier',
  'clearFormToast',
  'updateCarrierField',
  'saveCarrier',
  'getCarrierDetails'
])

export class CarrierStore extends Reflux.Store {
  constructor(props) {
    super(props)
    this.listenables = CarrierActions
    this.state = {
      status: null,
      carriers: [],
      carrierName: null,
      carrierDetails: {
        name: '',
        trackingPrefix: '',
        removed: false
      }
    }
  }

  getCarriers() {
    if (this.state.status === STATUS.IS_FETCHING) {
      return
    }

    this.setState({ status: STATUS.IS_FETCHING })
    GET('carriers/pool').then(responseJSON => {
      let nextState = {
        carriers: []
      }
      if (responseJSON) {
        if (responseJSON.error) {
          nextState.status = STATUS.FETCHING_ERROR
        } else {
          nextState.carriers = responseJSON
          nextState.status = null
        }
      }
      this.setState(nextState)
    })
  }

  clearToast() {
    this.setState({ status: null })
  }

  deleteCarrier(id = null) {
    if (id) {
      POST('carriers/removeCarrier', { CarrierID: id }).then(responseJSON => {
        if (responseJSON.error) {
          this.setState({ status: STATUS.DELETE_ERROR })
        } else {
          this.getCarriers()
        }
      })
    }
  }

  createEmptyCarrier() {
    this.setState({
      carrierDetails: {
        name: '',
        trackingPrefix: '',
        removed: false
      }
    })
  }

  clearFormToast(status) {
    status ? this.setState({ status }) : this.setState({ status: STATUS.TAKING_INPUT })
  }

  getCarrierDetails(id = null) {
    if (!id) {
      return
    }

    this.setState({ status: STATUS.IS_FETCHING })
    GET('carriers/fetch', { CarrierID: id }).then(responseJSON => {
      let nextState = {
        carrierDetails: {}
      }
      if (responseJSON) {
        if (responseJSON.error) {
          nextState.status = STATUS.FETCHING_ERROR
        } else {
          nextState.carrierDetails = responseJSON
          nextState.status = STATUS.TAKING_INPUT
        }
      }
      this.setState(nextState)
    })
  }

  updateCarrierField(field) {
    let fieldName = field.target.name
    let fieldValue
    if (fieldName === 'removed') {
      fieldValue = field.target.checked ? 1 : 0
    } else {
      fieldValue = field.target.value
    }
    let carrierDetails = this.state.carrierDetails
    carrierDetails[fieldName] = fieldValue
    this.setState({ carrierDetails })
  }

  saveCarrier() {
    let carrierDetails = this.state.carrierDetails
    this.setState({ status: STATUS.IS_SAVING })
    POST('carriers/replace', carrierDetails).then(responseJSON => {
      if (responseJSON) {
        if (responseJSON.error) {
          this.setState({ status: STATUS.UPDATE_ERROR })
        } else {
          this.setState({ status: STATUS.UPDATE_SUCCESS })
        }
      }
    })
  }
}
