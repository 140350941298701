import React from 'react'
import { Component } from 'reflux'
import { CarrierActions, CarrierStore, STATUS } from 'Stores/carrierStore'
import { Button, Form, Input, Row, Col, Label } from 'reactstrap'
import _ from 'lodash'
import CollapsibleCard from 'Components/collapsibleCard'
import { Redirect } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default class CarrierForm extends Component {
  constructor(props) {
    super(props)
    this.stores = [CarrierStore]
    this.storeKeys = ['status', 'carrierDetails', 'groups']
  }

  resetCarrier = () => {
    if (_.get(this.props, 'match.params.CarrierID')) {
      CarrierActions.getCarrierDetails(this.props.match.params.CarrierID)
    } else {
      CarrierActions.createEmptyCarrier()
    }
  }

  render() {
    if (this.state.status === STATUS.REDIRECT) {
      return <Redirect to='/carriers' />
    }
    if (this.state.status === STATUS.IS_FETCHING) {
      return <FontAwesomeIcon icon='spinner' spin />
    }
    return (
      <>
        <CollapsibleCard startOpen header={<strong>{_.get(this.props, 'match.params.CarrierID') ? 'Detaljer' : 'Opret'}</strong>}>
          <Form>
            <Row className='mb-2'>
              <Col xs='2'>
                <Label for='name'>Navn</Label>
              </Col>
              <Col xs='10'>
                <Input type='text' value={this.state.carrierDetails.name} name='name' id='name' onChange={CarrierActions.updateCarrierField} />
              </Col>
            </Row>
            <Row className='mb-2'>
              <Col xs='2'>
                <Label for='trackingPrefix'>Tracking Prefix</Label>
              </Col>
              <Col xs='10'>
                <Input
                  type='text'
                  value={this.state.carrierDetails.trackingPrefix}
                  name='trackingPrefix'
                  id='trackingPrefix'
                  onChange={CarrierActions.updateCarrierField}
                />
              </Col>
            </Row>

            <Button onClick={CarrierActions.saveCarrier} disabled={this.state.status === STATUS.IS_SAVING || !this.state.carrierDetails.name.length}>
              {this.state.status === STATUS.IS_SAVING ? <FontAwesomeIcon icon='spinner' spin /> : 'Gem'}
            </Button>
            <Button color='danger' onClick={this.resetCarrier} className='ml-2'>
              Nulstil
            </Button>
          </Form>
        </CollapsibleCard>
      </>
    )
  }
}
