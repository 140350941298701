import { createSlice } from '@reduxjs/toolkit'
import { STATUS } from 'Reducers/actions/supplierActions'

export const supplierSlice = createSlice({
  name: 'supplier',
  initialState: {
    overviewLoadingState: false,
    suppliers: [],
    carriers: [],
    supplierDetails: {
      supplierName: '',
      supplierUID: '',
      CarrierID: 0
    },
    loadingState: false,
    showActiveSuppliers: true,
    showInActiveSuppliers: false,
    supplierFilterValue: 'active',
    comments: []
  },
  reducers: {
    fetchSuppliersRequest: state => {
      state.overviewLoadingState = STATUS.FETCHING_SUPPLIERS
    },
    fetchSuppliersFailed: state => {
      state.overviewLoadingState = STATUS.FETCHING_SUPPLIERS_FAILED
    },
    fetchSuppliersSuccess: (state, action) => {
      state.suppliers = action.payload
      state.overviewLoadingState = STATUS.FETCHING_SUPPLIERS_SUCCESS
    },
    fetchSupplierDetailsRequest: state => {
      state.loadingState = STATUS.FETCHING_SUPPLIER_DETAILS
    },
    fetchSupplierDetailsFailed: state => {
      state.loadingState = STATUS.FETCHING_SUPPLIER_DETAILS_FAILED
    },
    fetchSupplierDetailsSuccess: (state, action) => {
      state.loadingState = STATUS.FETCHING_SUPPLIER_DETAILS_SUCCESS
      state.supplierDetails = action.payload
    },
    supplierCreationSuccess: (state, action) => {
      state.loadingState = action.payload
      state.loadingState = STATUS.SUPPLIER_SUCCESS
    },
    supplierCreationFailure: state => {
      state.loadingState = STATUS.SUPPLIER_FAILED
    },
    isSavingSupplier: state => {
      state.loadingState = STATUS.IS_SAVING
    },
    createEmptySupplier: state => {
      state.supplierDetails = {
        supplierName: '',
        supplierUID: '',
        CarrierID: 0
      }
    },
    clearToast: (state, action) => {
      state.loadingState = action.payload ? action.payload : null
      state.overviewLoadingState = false
    },
    updateSupplierField: (state, action) => {
      return {
        ...state,
        supplierDetails: { ...state.supplierDetails, [action.payload.name]: action.payload.value }
      }
    },
    removeSupplierFailure: state => {
      state.overviewLoadingState = STATUS.DELETE_SUPPLIER_FAILED
    },
    removeSupplierSuccess: state => {
      state.overviewLoadingState = STATUS.DELETE_SUPPLIER_SUCCESS
    },
    toggleActiveSupplier: state => {
      let value = !state.showActiveSuppliers ? 'active' : 'both'
      state.showActiveSuppliers = !state.showActiveSuppliers
      state.supplierFilterValue = value
      state.showInActiveSuppliers = false
    },
    toggleInActiveSupplier: state => {
      let value = state.showInActiveSuppliers ? 'both' : 'inactive'
      state.showInActiveSuppliers = !state.showInActiveSuppliers
      state.supplierFilterValue = value
      state.showActiveSuppliers = false
    },
    fetchCommentsSuccess: (state, action) => {
      state.comments = action.payload
    },
    fetchCommentsError: state => {
      state.loadingState = STATUS.FETCHING_COMMENTS_ERROR
    },
    createSupplierComment: (state, action) => {
      state.comments.push(action.payload)
    },
    createCommentError: state => {
      state.loadingState = STATUS.CREATE_COMMENT_ERROR
    },
    removeCommentSuccess: (state, action) => {
      state.comments = state.comments.filter(c => c.SupplierCommentID !== action.payload)
    },
    removeCommentError: state => {
      state.loadingState = STATUS.REMOVE_COMMENT_ERROR
    },
    createSupplierAccess: (state, action) => {
      const supplierAccess = action.payload
      state.supplierDetails.accesses.push(supplierAccess)
    },
    updateSupplierAccess: (state, action) => {
      const supplierAccess = action.payload
      state.supplierDetails.accesses = state.supplierDetails.accesses.map(a => {
        if (a.SupplierAccessID === supplierAccess.SupplierAccessID) {
          return supplierAccess
        } else {
          return a
        }
      })
    },
    removeSupplierAccess: (state, action) => {
      state.supplierDetails.accesses = state.supplierDetails.accesses.filter((_, i) => i !== action.payload)
    },
    fetchCarriersSuccess: (state, action) => {
      state.carriers = action.payload
    }
  }
})

export const {
  fetchSuppliersRequest,
  fetchSuppliersFailed,
  fetchSuppliersSuccess,
  fetchSupplierDetailsRequest,
  fetchSupplierDetailsFailed,
  fetchSupplierDetailsSuccess,
  supplierCreationSuccess,
  supplierCreationFailure,
  toggleActiveSupplier,
  toggleInActiveSupplier,
  removeSupplierFailure,
  resetSupplierDetails,
  isSavingSupplier,
  createEmptySupplier,
  clearToast,
  updateSupplierField,
  removeSupplierSuccess,
  fetchCommentsSuccess,
  createSupplierComment,
  removeCommentSuccess,
  fetchCommentsError,
  createCommentError,
  removeCommentError,
  createSupplierAccess,
  updateSupplierAccess,
  removeSupplierAccess,
  fetchCarriersSuccess
} = supplierSlice.actions

export default supplierSlice.reducer
