import React from 'react'
import { Component } from 'reflux'
import DynamicTable from 'Components/dynamicTable'
import CollapsibleCard from 'Components/collapsibleCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import { Button, ButtonGroup, UncontrolledTooltip as Tooltip } from 'reactstrap'
import 'moment/locale/da'
import { toast } from 'react-toastify'
import { CarrierActions, CarrierStore, STATUS } from 'Stores/carrierStore'

export default class CarrierList extends Component {
  constructor(props) {
    super(props)
    this.stores = [CarrierStore]
    this.storeKeys = ['carriers', 'status']
  }

  componentDidMount() {
    CarrierActions.getCarriers()
  }

  componentDidUpdate() {
    if (this.state.status === STATUS.FETCHING_ERROR) {
      toast.error('Fetching Carriers Failed', {
        autoClose: 7500,
        onClose: () => {
          CarrierActions.clearToast()
        }
      })
      CarrierActions.clearToast()
    }
    if (this.state.status === STATUS.DELETE_ERROR) {
      toast.error('Delete Carrier Failed', {
        autoClose: 7500,
        onClose: () => {
          CarrierActions.clearToast()
        }
      })
      CarrierActions.clearToast()
    }
    if (this.state.status === STATUS.DELETE_SUCCESS) {
      toast.success('Carrier Deleted', {
        autoClose: 7500,
        onClose: () => {
          CarrierActions.clearToast()
        }
      })
      CarrierActions.clearToast()
    }
  }

  render() {
    if (this.state.status === STATUS.IS_FETCHING) {
      return <FontAwesomeIcon icon='spinner' spin />
    }
    return (
      <CollapsibleCard startOpen header={<strong>Carriers</strong>}>
        <DynamicTable
          responsive
          size='sm'
          options={{
            startSort: {
              dir: 'DESC',
              column: 'CarrierID'
            },
            addField: e => {
              return (
                <td>
                  <ButtonGroup>
                    <Tooltip target={'edit' + e.CarrierID}>Rediger</Tooltip>
                    <Button tag={Link} to={'/carriers/' + e.CarrierID} id={'edit' + e.CarrierID}>
                      <FontAwesomeIcon icon='edit' />
                    </Button>
                    <Tooltip target={'delete' + e.CarrierID}>Slet</Tooltip>
                    <Button
                      id={'delete' + e.CarrierID}
                      color='danger'
                      onClick={() => {
                        if (confirm('Vil du slette denne transportør?')) {
                          CarrierActions.deleteCarrier(e.CarrierID)
                        }
                      }}
                    >
                      <FontAwesomeIcon icon='trash' />
                    </Button>
                  </ButtonGroup>
                </td>
              )
            }
          }}
          headers={{
            CarrierID: 'ID',
            name: 'Navn',
            trackingPrefix: 'Tracking Prefix'
          }}
          data={this.state.carriers}
        />
      </CollapsibleCard>
    )
  }
}
