import React, { Fragment } from 'react'
import { Component } from 'reflux'
import CollapsibleCard from 'Components/collapsibleCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Col, Form, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table, Tooltip } from 'reactstrap'
import _ from 'lodash'
import FormField from 'Components/formField'
import { Redirect } from 'react-router-dom'
import { STATUS, getCarriers, getSupplierDetails, saveSupplier, updateAccess, deleteSupplierAccess } from 'Reducers/actions/supplierActions'
import { clearToast, createEmptySupplier, updateSupplierField } from 'Reducers/supplierReducer'
import { connect } from 'react-redux'
import PasswordField from '../../components/passwordField'

class supplierForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      fieldValidation: {},
      hasFormBeenSubmitted: false,
      showAccessModal: false,
      currentAccess: this.getDefaultSupplierAccess()
    }
  }

  componentDidMount() {
    this.props.getCarriers()
  }

  getDefaultSupplierAccess = () => {
    return {
      SupplierAccessID: 0,
      url: '',
      username: '',
      password: '',
      note: ''
    }
  }

  resetData = () => {
    if (_.get(this.props, 'match.params.SupplierID')) {
      this.setState({ hasFormBeenSubmitted: false, fieldValidation: {} })
      this.props.getSupplierDetails(this.props.match.params.SupplierID)
    } else {
      this.setState({ hasFormBeenSubmitted: false, fieldValidation: {} })
      this.props.createEmptySupplier()
    }
  }

  validateForm = force => {
    if (!this.state.hasFormBeenSubmitted && !force) return

    const fieldValidation = {
      isNameValid: this.props.supplierDetails.supplierName ? true : false
    }

    this.setState({ fieldValidation })
    return Object.keys(fieldValidation).reduce((sum, next) => sum && fieldValidation[next], true)
  }

  saveSupplierForm = ev => {
    ev.preventDefault()
    const isFormValid = this.validateForm(true)
    this.setState({ hasFormBeenSubmitted: true })
    if (isFormValid) {
      this.props.saveSupplier(this.props.supplierDetails)
    }
  }

  closeAccessModal = () => {
    this.setState({ showAccessModal: false })
  }

  render() {
    if (this.props.loadingState === STATUS.IS_REDIRECT) {
      return <Redirect to='/suppliers' />
    }
    return (
      <React.Fragment>
        <CollapsibleCard startOpen={true} header={<strong>Leverandører</strong>}>
          <Form>
            <FormField
              required
              data={this.props.supplierDetails}
              field={'supplierName'}
              name='Name'
              onChange={e => {
                this.props.updateSupplierField({ name: e.target.name, value: e.target.value })
                this.validateForm()
              }}
            />
            <FormField
              data={this.props.supplierDetails}
              field={'supplierUID'}
              name='UID'
              onChange={e => {
                this.props.updateSupplierField({ name: e.target.name, value: e.target.value })
              }}
            />
            <FormField field='CarrierID' name='Transportør'>
              <Input
                type='select'
                name='CarrierID'
                value={this.props.supplierDetails.CarrierID || 0}
                id='CarrierID'
                onChange={e => this.props.updateSupplierField({ name: e.target.name, value: Number(e.target.value) })}
              >
                <option value={0}>Ingen</option>
                {this.props.carriers.map(c => (
                  <option value={c.CarrierID} key={c.CarrierID}>
                    {c.name}
                  </option>
                ))}
              </Input>
            </FormField>
            <FormField
              data={this.props.supplierDetails}
              field={'isDropship'}
              name='Dropship leverandør'
              onChange={e => {
                this.props.updateSupplierField({ name: e.target.name, value: e.target.value })
              }}
              type='checkbox'
              checkedValue={Number(1)}
              unCheckedValue={Number(0)}
            />
            {!!this.props.supplierDetails.isDropship && (
              <FormField
                type='number'
                data={this.props.supplierDetails}
                field={'dropshipDeliveryTimeDaysMin'}
                name='Min. dropship leveringstid (dage)'
                onChange={e => {
                  this.props.updateSupplierField({ name: e.target.name, value: Number(e.target.value) })
                }}
              />
            )}
            {!!this.props.supplierDetails.isDropship && (
              <FormField
                type='number'
                data={this.props.supplierDetails}
                field={'dropshipDeliveryTimeDaysMax'}
                name='Max. dropship leveringstid (dage)'
                onChange={e => {
                  this.props.updateSupplierField({ name: e.target.name, value: Number(e.target.value) })
                }}
              />
            )}
            {!!this.props.supplierDetails.isDropship && (
              <FormField
                type='number'
                data={this.props.supplierDetails}
                field={'dropshipDeliveryPrice'}
                name='Dropship leveringspris'
                onChange={e => {
                  this.props.updateSupplierField({ name: e.target.name, value: Number(e.target.value) })
                }}
              />
            )}
            <Row className='mb-2'>
              <Col xs='2'>
                <Label>Login</Label>
              </Col>
              <Col xs='11'>
                <Table className='mb-sm-0'>
                  <thead>
                    <tr>
                      <th style={{ width: '25%' }}>URL</th>
                      <th style={{ width: '30%' }}>Note</th>
                      <th>Brugernavn</th>
                      <th>Password</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {!!this.props.supplierDetails.accesses &&
                      this.props.supplierDetails.accesses.map((a, i) => (
                        <Fragment key={a.SupplierAccessID || i}>
                          <tr>
                            <td style={{ wordBreak: 'break-all' }}>
                              <a href={a.url}>{a.url}</a>
                            </td>
                            <td>{a.note}</td>
                            <td>{a.username}</td>
                            <td>
                              <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                                <PasswordField value={a.password} disabled />
                              </div>
                            </td>
                            <td style={{ width: 110 }}>
                              <Button onClick={() => this.setState({ currentAccess: a, showAccessModal: true })}>
                                <FontAwesomeIcon icon='edit' />
                              </Button>
                              <Button onClick={() => this.props.deleteSupplierAccess(i)} color='danger' style={{ marginLeft: 4 }}>
                                <FontAwesomeIcon icon='minus' />
                              </Button>
                            </td>
                          </tr>
                        </Fragment>
                      ))}
                  </tbody>
                </Table>
              </Col>
              <Col xs='1'>
                <Tooltip target='add-supplier-access-btn'>Tilføj adgang</Tooltip>
                <Button
                  id='add-supplier-access-btn'
                  onClick={() => this.setState({ currentAccess: this.getDefaultSupplierAccess(), showAccessModal: true })}
                >
                  <FontAwesomeIcon icon='plus' />
                </Button>
              </Col>
            </Row>
            <Button onClick={this.saveSupplierForm} disabled={this.props.loadingState === STATUS.IS_SAVING}>
              {this.props.loadingState === STATUS.IS_SAVING ? <FontAwesomeIcon icon='spinner' spin /> : 'Gem'}
            </Button>
            <Button color='danger' onClick={this.resetData} className='ml-2'>
              Nulstil
            </Button>
          </Form>
        </CollapsibleCard>

        <Modal isOpen={this.state.showAccessModal}>
          <ModalHeader toggle={() => this.closeAccessModal()}>Adgang</ModalHeader>
          <ModalBody style={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
            <div>
              URL
              <Input
                value={this.state.currentAccess.url}
                onChange={v => this.setState({ currentAccess: { ...this.state.currentAccess, url: v.target.value } })}
              />
            </div>

            <div>
              Brugernavn
              <Input
                value={this.state.currentAccess.username}
                onChange={v => this.setState({ currentAccess: { ...this.state.currentAccess, username: v.target.value } })}
              />
            </div>

            <div>
              Password
              <PasswordField
                value={this.state.currentAccess.password}
                onChange={v => this.setState({ currentAccess: { ...this.state.currentAccess, password: v } })}
              />
            </div>

            <div>
              Note
              <Input
                type='textarea'
                value={this.state.currentAccess.note}
                onChange={v => this.setState({ currentAccess: { ...this.state.currentAccess, note: v.target.value } })}
              />
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={() => {
                this.props.updateAccess(this.state.currentAccess)
                this.closeAccessModal()
              }}
            >
              Confirm
            </Button>
            <Button color='danger' onClick={() => this.closeAccessModal()}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  loadingState: state.supplier.loadingState,
  supplierDetails: state.supplier.supplierDetails,
  carriers: state.supplier.carriers
})

export default connect(mapStateToProps, {
  getSupplierDetails,
  clearToast,
  createEmptySupplier,
  updateSupplierField,
  saveSupplier,
  updateAccess,
  deleteSupplierAccess,
  getCarriers
})(supplierForm)
