import React from 'react'
import { Component } from 'reflux'
import { toast } from 'react-toastify'
import SupplierForm from 'Modules/supplier/supplierForm'
import { clearToast } from 'Reducers/supplierReducer'
import { STATUS, getSupplierDetails, fetchComments, createComment, removeComment } from 'Reducers/actions/supplierActions'
import { connect } from 'react-redux'
import CommentSection from '../../components/commentSection'
import CollapsibleCard from 'Components/collapsibleCard'

class SupplierEdit extends Component {
  constructor(props) {
    super(props)
  }
  componentDidMount() {
    this.props.getSupplierDetails(this.props.match.params.SupplierID)
    this.props.fetchComments(this.props.match.params.SupplierID)
  }
  componentDidUpdate() {
    if (this.props.loadingState === STATUS.FETCHING_SUPPLIER_DETAILS_FAILED) {
      toast.error('FETCHING SUPPLIER FAILED', {
        autoClose: 7500,
        onClose: () => {
          this.props.clearToast()
        }
      })
      this.props.clearToast()
    }

    if (this.props.loadingState === STATUS.SUPPLIER_FAILED) {
      toast.error('UPDATE SUPPLIER FAILED', {
        autoClose: 3000,
        onClose: () => {
          this.props.clearToast()
        }
      })
      this.props.clearToast()
    }
    if (this.props.loadingState === STATUS.SUPPLIER_SUCCESS) {
      toast.success('UPDATE SUPPLIER SUCCESSFUL', {
        autoClose: 3000,
        onClose: () => {
          this.props.clearToast()
        }
      })
      this.props.clearToast(STATUS.IS_REDIRECT)
    }
    if (this.props.loadingState === STATUS.FETCHING_COMMENTS_ERROR) {
      toast.error('FETCHING COMMENTS FAILED', {
        autoClose: 3000,
        onClose: () => {
          this.props.clearToast()
        }
      })
      this.props.clearToast()
    }
    if (this.props.loadingState === STATUS.CREATE_COMMENT_ERROR) {
      toast.error('CREATING COMMENT FAILED', {
        autoClose: 3000,
        onClose: () => {
          this.props.clearToast()
        }
      })
      this.props.clearToast()
    }
    if (this.props.loadingState === STATUS.REMOVE_COMMENT_ERROR) {
      toast.error('REMOVING COMMENT FAILED', {
        autoClose: 3000,
        onClose: () => {
          this.props.clearToast()
        }
      })
      this.props.clearToast()
    }
  }

  render() {
    return (
      <React.Fragment>
        <SupplierForm {...this.props} />

        <CollapsibleCard startOpen={true} header={<strong>Kommentarer</strong>}>
          <CommentSection
            comments={this.props.comments}
            createComment={message => {
              const comment = {
                SupplierCommentID: 0,
                SupplierID: +this.props.match.params.SupplierID,
                message: message
              }
              this.props.createComment(comment)
            }}
            removeComment={this.props.removeComment}
          />
        </CollapsibleCard>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({ loadingState: state.supplier.loadingState, comments: state.supplier.comments })

export default connect(mapStateToProps, { getSupplierDetails, fetchComments, createComment, removeComment, clearToast })(SupplierEdit)
