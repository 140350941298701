import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Input } from 'reactstrap'

const PasswordField = props => {
  const [showPassword, setShowPassword] = useState(false)

  return (
    <div style={{ position: 'relative', display: 'flex', alignItems: 'center', gap: 10, cursor: 'pointer' }}>
      <Input
        rows={1}
        disabled={props.disabled}
        type={showPassword ? 'text' : 'password'}
        value={props.value}
        onChange={v => props.onChange(v.target.value)}
        style={{ paddingRight: 30 }}
      />
      <FontAwesomeIcon style={{ position: 'absolute', right: 10 }} icon='eye' onClick={() => setShowPassword(!showPassword)} />
    </div>
  )
}

export default PasswordField
