/* eslint-disable eqeqeq */
import React from 'react'
import moment from 'moment'
import { Col, FormGroup, Input, Label, Row } from 'reactstrap'

const FormField = props => {
  let {
    checkedValue,
    children,
    data = {},
    field,
    name,
    onChange,
    required,
    type = 'text',
    unCheckedValue,
    noValue = false,
    validateFunction,
    fieldWidth,
    ...rest
  } = props
  let isValid = false
  let isInvalid = false
  let value = data[field] ? data[field] : ''
  if (required) {
    if (value == '') {
      isInvalid = true
    } else {
      isValid = true
    }
  }
  if (validateFunction) {
    if (validateFunction(value)) {
      isValid = true
    } else {
      isInvalid = true
    }
  }
  let isChecked = undefined
  let fieldProps = {}
  Object.assign(fieldProps, rest)
  Object.assign(fieldProps, {
    name: field,
    id: field,
    type: type,
    onChange: onChange
  })
  if (required || validateFunction) {
    Object.assign(fieldProps, {
      valid: isValid,
      invalid: isInvalid
    })
  }
  if (type == 'checkbox') {
    let returnData = {
      target: {
        name: field,
        value: unCheckedValue
      }
    }
    isChecked = false
    if (value == checkedValue) {
      isChecked = true
    }
    value = undefined
    noValue = true
    fieldProps.onChange = e => {
      if (e.target.checked) {
        returnData.target.value = checkedValue
      }
      onChange(returnData)
    }
    Object.assign(fieldProps, { checked: isChecked })
    Object.assign(fieldProps, { style: { marginLeft: 0 } })
  }
  if (type == 'date') {
    value = moment(value).format('YYYY-MM-DD')
  }
  if (!noValue) {
    Object.assign(fieldProps, { value: value })
  }
  return (
    <FormGroup>
      <Row>
        <Col xs='3'>
          <Label for={field}>{name}</Label>
        </Col>
        <Col xs={fieldWidth ? fieldWidth : '9'}>{children ? children : <Input {...fieldProps} />}</Col>
      </Row>
    </FormGroup>
  )
}

export default FormField
